/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import {
  OrderHistoryDetailLineRequest,
  OrderHistoryLineSearchRequest,
  OrderHistoryLineSearchResponse,
  OrderHistorySearchResponse,
  OrderNumber,
  ProblemDetails,
} from "./data-contracts";
import { ContentType, HttpClient, RequestParams } from "./http-client";

export class OrderHistory<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
  /**
   * No description
   *
   * @tags OrderHistory
   * @name OrderhistorySearchDetailsCreate
   * @summary Search order history by order history number
   * @request POST:/api/orderhistory/search/details
   * @secure
   */
  orderhistorySearchDetailsCreate = (
    query: {
      orderNumber: OrderNumber;
    },
    params: RequestParams = {},
  ) =>
    this.request<OrderHistorySearchResponse, ProblemDetails | void>({
      path: `/api/orderhistory/search/details`,
      method: "POST",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderHistory
   * @name OrderhistorySearchDetailsLinesCreate
   * @summary Search order history lines by order history number
   * @request POST:/api/orderhistory/search/details/lines
   * @secure
   */
  orderhistorySearchDetailsLinesCreate = (data: OrderHistoryDetailLineRequest, params: RequestParams = {}) =>
    this.request<OrderHistoryLineSearchResponse, ProblemDetails | void>({
      path: `/api/orderhistory/search/details/lines`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
  /**
   * No description
   *
   * @tags OrderHistory
   * @name OrderhistorySearchCreate
   * @summary Search order history lines
   * @request POST:/api/orderhistory/search
   * @secure
   */
  orderhistorySearchCreate = (data: OrderHistoryLineSearchRequest, params: RequestParams = {}) =>
    this.request<OrderHistoryLineSearchResponse, ProblemDetails | void>({
      path: `/api/orderhistory/search`,
      method: "POST",
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });
}
