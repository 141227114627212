import React, { Dispatch, SetStateAction } from 'react';
import { isEmpty } from 'lodash';
import { useTranslationQuery } from 'api/translations';
import { Badge } from 'components/shared/Badge';
import { Button, ButtonProps } from 'components/shared/Button';
import { Drawer, DrawerBody, DrawerFooter, DrawerHeader } from 'components/shared/Drawer';
import { Icon } from 'components/shared/Icon';
import { Sorting, SortingResponse } from 'components/shared/Sorting';
import { FilterRequest, FilterResponse, PriceSliderResponse } from 'generated/data-contracts';
import { PriceQueryProps } from '../RangeFilter';
import { setActiveCount, setActivePriceCount } from '../helpers';
import { MobileFilterFooter } from './MobileFilterFooter';
import { MobileFilterItem } from './MobileFilterItem';
import styles from './MobileFilterMenu.module.scss';

interface MobileFilterMenuProps {
	filters: FilterResponse[];
	changeFilterMobile: (sortBy: string, sortDirection: string) => void;
	priceSliderValues?: PriceSliderResponse;
	buttonClassName?: string;
	isMobile?: boolean;
	toBeSet?: FilterRequest[];
	toBeRemoved?: FilterRequest[];
	setToBeSetFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	setToBeRemovedFilters?: Dispatch<SetStateAction<FilterRequest[]>>;
	priceFilter?: PriceQueryProps;
	setPriceFilter?: Dispatch<SetStateAction<PriceQueryProps>>;
	resetFilters: () => void;
	sorting?: SortingResponse[];
	currentSorting?: SortingResponse;
	buttonVariant?: ButtonProps['variant'];
	buttonSize?: ButtonProps['size'];
	renderInPortal?: boolean;
	showBadge?: boolean;
	disabled?: boolean;
	buttonLabel?: string;
	submitButtonLabel?: string;
}

export const MobileFilterMenu: React.FunctionComponent<MobileFilterMenuProps> = ({
	filters,
	changeFilterMobile,
	buttonClassName,
	priceSliderValues,
	isMobile,
	toBeSet,
	toBeRemoved,
	setToBeSetFilters,
	setToBeRemovedFilters,
	priceFilter,
	setPriceFilter,
	resetFilters,
	sorting,
	currentSorting,
	buttonSize = 'sm',
	buttonVariant = 'dark',
	renderInPortal = true,
	showBadge = false,
	disabled,
	buttonLabel,
	submitButtonLabel,
}) => {
	const { data: translations } = useTranslationQuery();
	const [isOpen, setIsOpen] = React.useState(false);
	const [activeLevel, setActiveLevel] = React.useState<string | null>(null);
	const [renderedLevels, setRenderedLevels] = React.useState<(string | null)[]>([]);
	const [sortBy, setSortBy] = React.useState(currentSorting?.sortBy || '');
	const [sortByDirection, setSortByDirection] = React.useState(currentSorting?.sortDirection || '');

	const hasChanged =
		(priceFilter?.minPrice && priceFilter?.minPrice !== priceSliderValues?.selectedMinPrice) ||
		(priceFilter?.maxPrice && priceFilter?.maxPrice !== priceSliderValues?.selectedMaxPrice) ||
		(toBeSet?.length ?? 0) > 0 ||
		(toBeRemoved?.length ?? 0) > 0;

	const resetActiveLevel = (): void => setActiveLevel(null);

	const handleClose = (): void => {
		setIsOpen(false);

		setTimeout(() => {
			resetActiveLevel();
		}, 400);
	};

	const handleOpen = (): void => {
		setIsOpen(true);
	};

	const setSorting = (sort: string, sortDirection: string): void => {
		setSortBy(sort);
		setSortByDirection(sortDirection);
	};

	const handleLevelActivation = (levelId: string): void => {
		setActiveLevel(levelId);
		setRenderedLevels((arr) => [...arr, levelId]);
	};

	const handleReset = (): void => {
		resetFilters();
		handleClose();
	};

	const handleSubmit = (): void => {
		changeFilterMobile(sortBy, sortByDirection);
		handleClose();
	};

	const handleBack = (): void => {
		changeFilterMobile(sortBy, sortByDirection);
		resetActiveLevel();
	};

	const filtersHaveValues = filters.some(
		(f) => f?.values?.some((v) => v.isSelected) || f?.selectedMin || f?.selectedMax,
	);
	const priceFilterHasValues =
		!isEmpty(priceSliderValues) && (priceSliderValues?.selectedMinPrice || priceSliderValues?.selectedMaxPrice);
	const sortByHasChanged = sortBy !== currentSorting?.sortBy || sortByDirection !== currentSorting?.sortDirection;

	const hideFooter = !hasChanged && !filtersHaveValues && !priceFilterHasValues && !sortByHasChanged;

	const getActiveCount = React.useCallback(
		(filter): number => {
			if (filter) {
				return setActiveCount(filter);
			}

			return setActivePriceCount(priceSliderValues);
		},
		[priceSliderValues],
	);

	const totalNumOfFilters = filters.reduce((acc, f) => acc + getActiveCount(f), 0);

	return (
		<>
			{/* Trigger button */}
			<Button
				className={buttonClassName}
				size={buttonSize}
				variant={buttonVariant}
				onClick={handleOpen}
				disabled={disabled}
			>
				<Icon name={'filters'} size={'md'} />
				<span>{buttonLabel ?? translations?.productList.filters.filtersAndSort}</span>
				{showBadge && totalNumOfFilters > 0 && <Badge value={totalNumOfFilters} size={'sm'} />}
			</Button>

			{/* Filter menu */}
			{isMobile && isOpen && (
				<Drawer onClose={handleClose} direction="Right" renderInPortal={renderInPortal}>
					<DrawerHeader
						handleClose={handleClose}
						title={translations?.productList?.filters?.allFilters}
						titleSize="md"
						size="sm"
					/>
					<DrawerBody className={styles.mainContent}>
						<ul className={styles.list}>
							{sorting && currentSorting && (
								<li className={styles.item}>
									<Sorting
										sorting={sorting}
										isMobile={isMobile}
										currentSorting={currentSorting}
										setSorting={setSorting}
										activeLevel={activeLevel || ''}
										handleClose={handleClose}
										handleBack={resetActiveLevel}
										handleLevelActivation={handleLevelActivation}
										handleReset={handleReset}
										handleSubmit={handleSubmit}
										hideFooter={hideFooter}
									/>
								</li>
							)}
							{/* Regular filters */}
							{filters.map((item, index) => (
								<li className={styles.item} key={`${item?.id}-${index}`}>
									<MobileFilterItem
										item={item}
										getActiveCount={getActiveCount}
										handleBack={handleBack}
										handleLevelActivation={handleLevelActivation}
										handleReset={handleReset}
										handleSubmit={handleSubmit}
										renderedLevels={renderedLevels}
										activeLevel={activeLevel}
										hideFooter={hideFooter}
										priceSliderValues={priceSliderValues}
										priceFilter={priceFilter}
										setPriceFilter={setPriceFilter}
										toBeSet={toBeSet}
										toBeRemoved={toBeRemoved}
										setToBeSetFilters={setToBeSetFilters}
										setToBeRemovedFilters={setToBeRemovedFilters}
									/>
								</li>
							))}
						</ul>
					</DrawerBody>
					<DrawerFooter className={styles.footerContainer}>
						{!hideFooter && (
							<MobileFilterFooter
								handleReset={handleReset}
								handleSubmit={handleSubmit}
								submitButtonLabel={submitButtonLabel}
							/>
						)}
					</DrawerFooter>
				</Drawer>
			)}
		</>
	);
};
