import React from 'react';
import { useTranslationQuery } from 'api/translations';
import { Badge } from 'components/shared/Badge';
import { Button } from 'components/shared/Button';
import { DrawerFooter, DrawerHeaderWBackButton, DrawerLevel } from 'components/shared/Drawer';
import { Icon } from 'components/shared/Icon';
import { FilterRequest, FilterResponse, FilterType, PriceSliderResponse } from 'generated/data-contracts';
import { FilterContent } from '../../FilterContent';
import { PriceQueryProps } from '../../RangeFilter';
import { MobileFilterFooter } from '../MobileFilterFooter';
import styles from './MobileFilterItem.module.scss';

interface MobileFilterItemProps {
	renderedLevels: (string | null)[];
	activeLevel: string | null;
	item?: FilterResponse;
	priceSliderValues?: PriceSliderResponse;
	hideFooter?: boolean;
	toBeSet?: FilterRequest[];
	toBeRemoved?: FilterRequest[];
	priceFilter?: PriceQueryProps;
	handleLevelActivation: (levelId: string) => void;
	getActiveCount: (filter: FilterResponse | undefined) => number;
	handleBack: () => void;
	handleSubmit: () => void;
	handleReset: () => void;
	setPriceFilter?: React.Dispatch<React.SetStateAction<PriceQueryProps>>;
	setToBeRemovedFilters?: React.Dispatch<React.SetStateAction<FilterRequest[]>>;
	setToBeSetFilters?: React.Dispatch<React.SetStateAction<FilterRequest[]>>;
}

export const MobileFilterItem: React.FC<MobileFilterItemProps> = ({
	renderedLevels,
	activeLevel = '',
	item,
	priceSliderValues,
	hideFooter,
	toBeSet,
	toBeRemoved,
	priceFilter,
	handleLevelActivation,
	getActiveCount,
	handleBack,
	handleSubmit,
	handleReset,
	setPriceFilter,
	setToBeSetFilters,
	setToBeRemovedFilters,
}) => {
	const { data: translations } = useTranslationQuery();
	const priceId = 'price';
	const priceLabel = translations?.productList.filterDisplayName.price;

	const getId = (): string => (item ? item.id : priceId);

	return (
		<>
			<Button
				className={styles.filterButton}
				variant={'ghost'}
				hasOnlyIcon
				onClick={(): void => handleLevelActivation(getId())}
			>
				<span className={styles.filterLabelWrapper}>
					<span className={styles.filterLabel}>{item ? item.name : priceLabel}</span>
					<Badge innerClassName={styles.badge} value={getActiveCount(item)} size={'sm'} />
				</span>
				<Icon className={'u-ml-sm'} name={'chevronDown'} size={'lg'} rotate={270} />
			</Button>

			{activeLevel === getId() && (
				<DrawerLevel isActive={activeLevel === getId()}>
					{renderedLevels.includes(getId()) && (
						<DrawerHeaderWBackButton
							handleClose={handleSubmit}
							handleBack={handleBack}
							title={item ? item.name : priceLabel}
						/>
					)}
					{item ? (
						<FilterContent
							{...item}
							isFullScreen
							toBeSet={toBeSet}
							toBeRemoved={toBeRemoved}
							setToBeSetFilters={setToBeSetFilters}
							setToBeRemovedFilters={setToBeRemovedFilters}
							priceFilter={priceFilter}
							setPriceFilter={setPriceFilter}
						/>
					) : (
						<FilterContent
							type={FilterType.Number}
							name={translations?.productList.filters.price || ''}
							id={'price'}
							min={priceSliderValues?.minPrice}
							max={priceSliderValues?.maxPrice}
							selectedMin={priceSliderValues?.selectedMinPrice}
							selectedMax={priceSliderValues?.selectedMaxPrice}
							currencySymbol={priceSliderValues?.currencySymbol}
							isPriceFilter
							isFullScreen
							toBeSet={toBeSet}
							toBeRemoved={toBeRemoved}
							setToBeSetFilters={setToBeSetFilters}
							setToBeRemovedFilters={setToBeRemovedFilters}
							priceFilter={priceFilter}
							setPriceFilter={setPriceFilter}
						/>
					)}
					<DrawerFooter className={styles.footerContainer}>
						{!hideFooter && <MobileFilterFooter handleReset={handleReset} handleSubmit={handleSubmit} />}
					</DrawerFooter>
				</DrawerLevel>
			)}
		</>
	);
};
