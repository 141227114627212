import { Builder, withChildren } from '@builder.io/react';
import { DAMVideo } from '.';

const pageOrigin = window.location.origin;

const DAMVideoWithChildren = withChildren(DAMVideo);

Builder.registerComponent(DAMVideoWithChildren, {
	name: 'DamVideo',
	image: pageOrigin + '/dist/images/cms/video.svg',
	noWrap: true,
	canHaveChildren: true,

	inputs: [
		{
			name: 'video',
			type: 'Bizzkit DAM File',
			required: true,
		},
		{
			name: 'posterImage',
			type: 'Bizzkit DAM File',
			required: false,
		},
		{
			name: 'controls',
			type: 'boolean',
			defaultValue: true,
		},
		{
			name: 'autoPlay',
			type: 'boolean',
			defaultValue: false,
		},
		{
			name: 'loop',
			type: 'boolean',
			defaultValue: false,
		},
		{
			name: 'muted',
			type: 'boolean',
			defaultValue: true,
		},
		{
			name: 'fitContent',
			type: 'boolean',
			helperText: `When child blocks are provided, fit to them instead of using the image's aspect ratio`,
			defaultValue: true,
		},
		{
			name: 'objectFit',
			friendlyName: 'Fit video',
			type: 'string',
			defaultValue: 'cover',
			enum: [
				{
					label: 'contain',
					value: 'contain',
					helperText: 'The video should never get cropped',
				},
				{
					label: 'cover',
					value: 'cover',
					helperText: 'The video should fill its container, cropping when needed',
				},
			],
		},
		{
			name: 'aspectRatio',
			type: 'Bestseller Range Slider',
			helperText: `Height/width ratio, e.g. set to 1.5 for a 300px wide and 200px tall video. Set to 0 for video
							to maintain its natural aspect ratio`,
			defaultValue: 0,
			min: 0,
			max: 10,
			step: 0.01,
		},
		{
			name: 'videoPosition',
			type: 'object',
			defaultValue: {
				x: 50,
				y: 50,
			},
			subFields: [
				{
					name: 'x',
					friendlyName: 'Horizontal',
					type: 'Bestseller Range Slider',
					helperText: `Horizontal position of the video within its container when cropped, e.g. set to 50 for
									center, 0 for left, 100 for right`,
					defaultValue: 50,
					min: 0,
					max: 100,
					step: 1,
				},
				{
					name: 'y',
					friendlyName: 'Vertical',
					type: 'Bestseller Range Slider',
					helperText: `Vertical position of the video within its container when cropped, e.g. set to 50 for
									center, 0 for top, 100 for bottom`,
					defaultValue: 50,
					min: 0,
					max: 100,
					step: 1,
				},
			],
		},
	],
});
